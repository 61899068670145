import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useToggleState, useTranslateOptions } from '@/shared/hooks';
import { getNumberValidFilter } from '@/shared/utils';
import { FormStyle, UploadButton } from '@/shared/atoms';
import { MarketplaceCampaignAppliedStatus, MarketplaceDetail } from '@/graphql';
import { SelectorField, TextSearchField, BasicFilterWrapper, UploadCsvModal } from '@/shared/molecules';
import { AdvancedFilter } from './AdvancedFilter';
import { MarketplaceApplicantsFilterFormKeys, MarketplaceApplicantsFilterFormValues } from './schemaTypes';
import { useUploadMarketplaceApplicantsFile } from './hooks';

export interface BasicFilterProps {
  onSubmit?: () => void;
  marketplace: MarketplaceDetail;
}

export const BasicFilter = ({ onSubmit, marketplace }: BasicFilterProps) => {
  const { t } = useTranslation();
  const uploadModal = useToggleState();
  const { getValues } = useFormContext<MarketplaceApplicantsFilterFormValues>();
  const { uploading, handleUploadFile } = useUploadMarketplaceApplicantsFile({ campaignId: marketplace.id });
  const allAppliedStatusesOptions = useTranslateOptions([
    { value: MarketplaceCampaignAppliedStatus.APPROVED, label: 'Approved' },
    { value: MarketplaceCampaignAppliedStatus.REJECTED, label: 'Rejected' },
    { value: MarketplaceCampaignAppliedStatus.APPLYING, label: 'Applied' }
  ]);

  const values = getValues();
  const basicFilterKeys: MarketplaceApplicantsFilterFormKeys[] = ['keyword', 'appliedStatus'];
  const numOfValidFilter = getNumberValidFilter(values, basicFilterKeys);

  return (
    <>
      <BasicFilterWrapper>
        <TextSearchField<MarketplaceApplicantsFilterFormKeys>
          name="keyword"
          onSubmit={onSubmit}
          placeholder={t<string>('Search.InfluencerFilter')}
        />
        <SelectorField<MarketplaceApplicantsFilterFormKeys>
          onSubmit={onSubmit}
          name="appliedStatus"
          css={{ maxWidth: '198px' }}
          options={allAppliedStatusesOptions}
          placeholder={t<string>('Selector.Status')}
        />
        <AdvancedFilter count={numOfValidFilter} onSubmit={onSubmit} marketplace={marketplace} />
        <FormStyle.FieldWrapper css={{ flex: 'none' }}>
          <UploadButton onClick={uploadModal.open} loading={uploading} />
        </FormStyle.FieldWrapper>
      </BasicFilterWrapper>

      <UploadCsvModal
        uploading={uploading}
        open={uploadModal.status}
        onClose={uploadModal.close}
        onCustomUpload={handleUploadFile}
        dropAreaDescription={t('drop the file and upload')}
        description={t('mkpApplicantsBulkUploadCsvDescription')}
        notes={[
          t('General.DragAndDropMaxSize', { maxSize: '10MB' }),
          t('General.DragAndDropMIME', { MIME: 'CSV' })
        ]}
      />
    </>
  );
};
