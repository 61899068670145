import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMarketplacePermissions } from '@/auth';
import { ApplicantInfluencer } from '@/graphql';
import { useSelectItems } from '@/shared/hooks';
import { MarketplaceApplicantsSortProps, MarketplaceApplicantsListProps } from '@/shared/organisms';
import { formatIntNumber } from '@/shared/utils';

interface Props {
  limit: number;
  totalRecords: number;
  listApplicants: readonly ApplicantInfluencer[];
}

// Mixed flows between select applicants in when there is only one page and multiple pages
// There is only one page: select single/all applicants as usual, allow approve/reject selected applicants
// Multiple pages:
// - Select all button mean select all applicants across all pages
// - Not allow approve/reject selected applicants
export const useToggleSelectedApplicants = ({ limit, totalRecords, listApplicants }: Props) => {
  const { t } = useTranslation();
  const [isSelectAll, setIsSelectAll] = useState(false);
  const { hideToggleApplicantFromProposalBtn } = useMarketplacePermissions();
  const {
    selectedItems: selectedApplicants,
    selectedItemIds: selectedApplicantIds,
    hasAllItemsSelected: hasAllApplicantsSelectedInPage,
    selectItem: selectApplicant,
    setSelectedItemIds: setSelectedApplicantIds,
    toggleSelectAllItems: toggleSelectAllApplicants
  } = useSelectItems({ listItems: listApplicants, key: 'id' });

  const hasOnlyOnePage = totalRecords <= limit;

  useEffect(() => {
    if (hasOnlyOnePage) {
      // Only one page: isSelectAll triggered by the selected applicants in page
      setIsSelectAll(hasAllApplicantsSelectedInPage);
    } else if (isSelectAll && !hasAllApplicantsSelectedInPage) {
      // Multiple pages:
      // - Select all button will trigger isSelectAll to be true and select all applicants in current page
      // - If user unselect a applicant in current page, isSelectAll will be false
      setIsSelectAll(false);
    }
  }, [isSelectAll, hasOnlyOnePage, hasAllApplicantsSelectedInPage]);

  const sortSelectButtonProps: MarketplaceApplicantsSortProps['selectButtonProps'] = {
    onClick: toggleSelectAllApplicants,
    disabled: listApplicants.length === 0,
    checked: selectedApplicantIds.length > 0,
    indeterminate: hasOnlyOnePage ? listApplicants.length > 0 && !hasAllApplicantsSelectedInPage : !isSelectAll,
    label:
      selectedApplicantIds.length > 0
        ? t('influencer selected', {
            count: hasOnlyOnePage
              ? selectedApplicantIds.length
              : isSelectAll
                ? totalRecords
                : selectedApplicantIds.length
          })
        : `${formatIntNumber(totalRecords)} ${t('Influencers found')}`,
    tooltipProps: !hasOnlyOnePage
      ? {
          help: t<string>(hasAllApplicantsSelectedInPage ? 'Unselect all' : 'Select all')
        }
      : undefined
  };
  const generateListSelectButtonProps: MarketplaceApplicantsListProps['generateSelectButtonProps'] = ({ id }) => ({
    type: 'checkbox',
    onClick: () => selectApplicant(id),
    checked: selectedApplicantIds.includes(id)
  });

  const handleSelectAll = () => {
    if (hasOnlyOnePage) {
      toggleSelectAllApplicants();
    } else {
      setIsSelectAll(true);

      // Toggle select all applicants in current page
      if (!hasAllApplicantsSelectedInPage) {
        toggleSelectAllApplicants();
      }
    }
  };

  return {
    selectedApplicants,
    setSelectedApplicantIds,
    isApplicantsAllPagesSelected: !hasOnlyOnePage && isSelectAll,
    onSelectAll: listApplicants.length > 0 ? handleSelectAll : undefined,
    sortSelectButtonProps: !hideToggleApplicantFromProposalBtn ? sortSelectButtonProps : undefined,
    generateListSelectButtonProps: !hideToggleApplicantFromProposalBtn ? generateListSelectButtonProps : undefined
  };
};
