import { Trans, useTranslation } from 'react-i18next';
import { Notice } from '@/shared/atoms';
import { THEME } from '@/shared/constants';
import { formatDate } from '@/shared/utils';
import { FilterWrapper, ListWithPagination } from '@/shared/molecules';
import { MarketplaceApplicantsSort, MarketplaceApplicantsList, MarketplaceApplicantsFilter } from '@/shared/organisms';
import { useMarketplaceDetailsContext } from '../MarketplaceDetailsContext';
import { useMarketplaceApplicantsList, useToggleSelectedApplicants } from './hooks';

export const MarketplaceApplicants = () => {
  const { t } = useTranslation();
  const { marketplace } = useMarketplaceDetailsContext();
  const { data, limit, loading, noInfluencersJoined } = useMarketplaceApplicantsList();

  const listApplicants = data?.allApplicantInfluencersForMarketplace || [];
  const totalRecords = data?.allApplicantInfluencersForMarketplaceCount?.totalNumber || 0;

  const {
    onSelectAll,
    selectedApplicants,
    sortSelectButtonProps,
    setSelectedApplicantIds,
    isApplicantsAllPagesSelected,
    generateListSelectButtonProps
  } = useToggleSelectedApplicants({ limit, totalRecords, listApplicants });

  return (
    <>
      <Notice
        type="info"
        title={t('Applicant Influencers')}
        description={
          <Trans
            i18nKey="preLaunchNotice"
            values={{ date: formatDate(marketplace.startDate || '') }}
            components={{ span: <span css={{ color: THEME.text.colors.red.lv1, marginLeft: '4px' }} /> }}
          />
        }
      />

      <ListWithPagination pagination={{ totalRecords }}>
        <FilterWrapper>
          <MarketplaceApplicantsFilter marketplace={marketplace} />
          <MarketplaceApplicantsSort
            marketplace={marketplace}
            onSelectAll={onSelectAll}
            selectedApplicants={selectedApplicants}
            selectButtonProps={sortSelectButtonProps}
            resetSelectedApplicants={() => setSelectedApplicantIds([])}
            isApplicantsAllPagesSelected={isApplicantsAllPagesSelected}
          />
        </FilterWrapper>

        <MarketplaceApplicantsList
          loading={loading}
          data={listApplicants}
          totalRecords={totalRecords}
          noInfluencersJoined={noInfluencersJoined}
          generateSelectButtonProps={generateListSelectButtonProps}
        />
      </ListWithPagination>
    </>
  );
};
