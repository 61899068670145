import { useMutation, MutationHookOptions } from '@apollo/client';
import MUTATION from '../operations/UploadMarketplaceApplicantFile.graphql';
import { Mutation, MutationuploadMarketplaceApplicantFileArgs } from '../../__generated__/globalTypes';

export const useUploadMarketplaceApplicantFileMutation = (
  options?: MutationHookOptions<Mutation, MutationuploadMarketplaceApplicantFileArgs>
) => {
  const [callUploadMarketplaceApplicantFile, result] = useMutation<
    Mutation,
    MutationuploadMarketplaceApplicantFileArgs
  >(MUTATION, options);

  return { callUploadMarketplaceApplicantFile, ...result };
};

export type UploadMarketplaceApplicantFileMutationFunction = Mutation['uploadMarketplaceApplicantFile'];
