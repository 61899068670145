import { useState } from 'react';
import axios from 'axios';
import {
  namedOperations,
  MarketplaceDetail,
  useUploadMarketplaceApplicantFileMutation,
  useGenerateUploadReportSignedUrlPromiseQuery
} from '@/graphql';
import { useQueryHelper } from '@/shared/hooks';

interface Props {
  campaignId: MarketplaceDetail['id'];
}

export const useUploadMarketplaceApplicantsFile = ({ campaignId }: Props) => {
  const [uploading, setUploading] = useState(false);
  const { t, enqueueSnackbar } = useQueryHelper();
  const { getGenerateUploadReportSignedUrl } = useGenerateUploadReportSignedUrlPromiseQuery();
  const { callUploadMarketplaceApplicantFile } = useUploadMarketplaceApplicantFileMutation({
    refetchQueries: [namedOperations.Query.AllApplicantInfluencersForMarketplace]
  });

  const uploadFileToGCSAndBackend = async (signedUrl: string, accepted: File) => {
    try {
      await axios(signedUrl, { method: 'PUT', data: accepted });
      const urlObjFromSignedUrl = new URL(signedUrl);
      // full path url without search
      const uploadFileUrl = urlObjFromSignedUrl.origin + urlObjFromSignedUrl.pathname;
      await callUploadMarketplaceApplicantFile({ variables: { input: { id: campaignId, uploadFileUrl } } });
      enqueueSnackbar(t('uploadMarketplaceApplicantFileSuccess'), { variant: 'success' });
    } catch (error) {
      enqueueSnackbar(t(error?.message || 'fileUploadFail'), { variant: 'error' });
    }
  };

  const handleUploadFile = async (file: File) => {
    try {
      setUploading(true);
      const { data } = await getGenerateUploadReportSignedUrl({ variables: { fileNames: [file.name] } });

      const signedUrlResp = data?.generateUploadReportSignedUrl.fileUploadUrls[0] || null;

      if (signedUrlResp) {
        await uploadFileToGCSAndBackend(signedUrlResp.signedUrl, file);
      } else {
        enqueueSnackbar(t('fileUploadFail'), { variant: 'error' });
      }
    } catch (error) {
      enqueueSnackbar(t(error?.message || 'fileUploadFail'), { variant: 'error' });
    } finally {
      setUploading(false);
    }
  };

  return { handleUploadFile, uploading };
};
